<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Création de votre compte
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->
    <!-- Start Blog Area  -->
    <div class="rn-blog-area ptb--60 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div>
              <v-stepper alt-labels v-model="e1">
                <v-stepper-header>
                  <v-stepper-step step="1">
                    Informations
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="2">
                    Abonnement
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">
                    Amis
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4">
                    Assistant Vocal
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <FormAccount v-on:updateuser="nextStep(2)"></FormAccount>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <PricingPlan v-on:updateplan="nextStep(3)"> </PricingPlan>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <FormContact v-on:inserted="nextStep(4)"></FormContact>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <ActivateApp v-on:inserted="end()"></ActivateApp>
                    <v-col cols="12">
                      <div class="text-center section-title">
                        <p style="font-size: 12px;padding-bottom: 10px">
                          <a href="/user/dashboard">passer cette étape</a>
                        </p>
                      </div>
                    </v-col>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Area  -->
  </div>
</template>

<script>
import FormAccount from "./widget/FormAccount";
import PricingPlan from "../../components/pricing-plan/PricingPlanUser";
import FormContact from "@/views2/user/widget/FormContact";
import ActivateApp from "@/views2/user/widget/ActivateApp";

export default {
  components: { FormAccount, PricingPlan, FormContact, ActivateApp },
  data() {
    return {
      e1: 1,
      steps: 4
    };
  },
  watch: {
    steps(val) {
      this.$gtm.trackEvent({
        category: "User",
        action: "OnBoarding",
        label: "step",
        value: val
      });
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  methods: {
    nextStep(n) {
      if (n === this.steps + 1) {
        this.e1 = 1;
      } else {
        this.e1 = n;
      }
    },
    end() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
